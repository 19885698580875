import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import "./_index.scss"

const SuccessScreen = () => {
  return (
    <section id="success-section">
      <Container>
        <Row className="justify-content-center success-wrapper">
          <Col lg={5} className="text-center success-text-wrap m-auto">
            <img
              src={require("../../../static/icons/succes-popup-icon.svg")}
              alt=""
            />

            <h1>
              We will contact you soon by email and provide you log in details
            </h1>

            <Link to="/">
              <button className="btn ok-btn">ok</button>
            </Link>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row>
          <Col className="p-0 bottom-rectangle position-absolute">
            <img
              className="img-fluid w-100"
              src={require("../../../static/rectangle.svg")}
              alt="rectangle"
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default SuccessScreen
