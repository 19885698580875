import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SuccessScreen from "../components/success-screen"

const ThankYouPage = () => (
  <Layout>
    <SEO title="Thank You" />
    <SuccessScreen />
  </Layout>
)

export default ThankYouPage
